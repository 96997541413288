import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Panel from 'components/Panel/Panel';
import Form from 'components/Form/Form';
import img from 'assets/img/bi-gout.png';

const useStyles = makeStyles({
  grid: {
    height: '100vh'
  },
  img: {
    marginBottom: 16,
    marginTop: 16,
    marginLeft: 16
  },
  buttonPrevDesktop: {
    marginTop: 40,
    height: 42,
    borderRadius: 8,
    backgroundColor: '#ffff',
    border: 'solid 2px #E85313',
    color: '#E85313',
    fontFamily: 'Work Sans',
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    marginBottom: 15
  },
  buttonNextDesktop: {
    fontFamily: 'Work Sans',
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    marginTop: 40,
    marginLeft: 26,
    height: 42,
    borderRadius: 8,
    backgroundColor: '#E85313',
    color: '#ffff',
    marginBottom: 15
  },
  buttonPrevMobile: {
    marginTop: 40,
    marginBottom: 16,
    width: '90%',
    height: 42,
    borderRadius: 8,
    backgroundColor: '#ffff',
    border: 'solid 2px #E85313',
    color: '#E85313'
  },
  buttonNextMobile: {
    width: '90%',
    height: 42,
    borderRadius: 8,
    backgroundColor: '#E85313',
    color: '#ffff',
    marginBottom: 15
  }
});

export default function Finalisation(props) {
  const classes = useStyles();

  const formReducer = useSelector(store => store.formReducer);

  if (!formReducer.jwt || (formReducer.jwt && formReducer.jwt.data === null)) {
    return <Redirect to="/step-1" />;
  }

  return (
    <Grid className={classes.grid} container justify="flex-end">
      <Hidden only={['md', 'sm', 'lg', 'xl']}>
        <Grid item xs={12} className={classes.img}>
          <img src={img} alt="group" />
        </Grid>
      </Hidden>

      <Hidden only={'xs'}>
        <Grid item xs={12} sm={4} lg={4} xl={3}>
          <Panel step={4} history={props.history} final />
        </Grid>
      </Hidden>

      <Hidden only={'xs'}>
        <Grid item xs={12} sm={8} lg={8} xl={9}>
          <Form
            step={4}
            justify="center"
            buttonPrevDesktop={classes.buttonPrevDesktop}
            buttonNextDesktop={classes.buttonNextDesktop}
          />
        </Grid>
      </Hidden>

      <Hidden only={['md', 'sm', 'lg', 'xl']}>
        <Grid item xs={12} sm={8} lg={9}>
          <Form
            step={4}
            justify="flex-start"
            buttonPrevMobile={classes.buttonPrevMobile}
            buttonNextMobile={classes.buttonNextMobile}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
}

Finalisation.propTypes = {
  history: PropTypes.object
};
