import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import appActions from 'redux/actions/appActions';
import { useDispatch, useSelector } from 'react-redux';
import { handleCodificationsInfobulles } from 'utils';

import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';

import Tooltip from '@material-ui/core/Tooltip';

export default function TextFieldAutoComplete(props) {
  const useStyles = makeStyles({
    textField: {
      marginLeft: props.justify === 'flex-start' && 16,
      width: 134,
      height: 44,
      borderRadius: 4,
      border: 'solid 0px #d6d6d6'
    },
    select: {
      width: props.justify === 'flex-start' ? '90%' : 500,
      height: 44,
      backgroundColor: '#ffffff',
      marginLeft: props.justify === 'flex-start' && 16
    },
    paragraphe: {
      fontFamily: 'Work Sans',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: '#4a4a49'
    },
    title: {
      fontFamily: 'Work Sans',
      fontSize: 14,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      fontWeight: 600,
      color: '#e85211',
      display: 'flex',
      marginBottom: 20,
      marginTop: 10
    },
    tooltip: {
      maxWidth: 369,
      height: 153,
      borderRadius: 8,
      boxShadow: '0 0 14px 0 rgba(56, 20, 5, 0.14), 0 0 4px 0 rgba(60, 22, 6, 0.07)',
      backgroundColor: '#ffffff'
    },
    alert: {
      width: props.justify === 'flex-start' ? '80%' : 470,
      minHeight: 89,
      borderRadius: 8,
      backgroundColor: '#FFF1EC',
      marginLeft: props.justify === 'flex-start' && 16,
      marginTop: 15
    },
    infosAlert: {
      fontFamily: 'Work Sans',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.36,
      letterSpacing: 'normal',
      color: '#D7805B',
      marginLeft: props.justify === 'flex-start' && 16,
      width: props.justify === 'flex-start' && 253
    }
  });

  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          maxWidth: 369,
          borderRadius: 8,
          boxShadow: '0 0 14px 0 rgba(56, 20, 5, 0.14), 0 0 4px 0 rgba(60, 22, 6, 0.07)',
          backgroundColor: '#ffffff',
          fontSize: 14,
          fontFamily: 'Work Sans',
          color: '#4a4a49'
        }
      }
    }
  });

  const color = createMuiTheme({
    palette: {
      primary: { main: '#E85313' }
    }
  });

  const classes = useStyles();

  const dispatch = useDispatch();

  const [activite, setActivite] = useState(null);
  const [city, setCity] = useState(null);
  const [cp, setCp] = useState(null);
  const [reseau, setReseau] = useState(null);

  const codesPostaux = useSelector(store => store.appReducer.codesPostaux);
  const activiteRedux = useSelector(store => store.appReducer.listActivite);
  const referentiel = useSelector(store => store.appReducer.referentielReseau);
  const cityRedux = useSelector(store => store.appReducer.city);
  const formReducer = useSelector(store => store.formReducer);
  const codifications = useSelector(store => store.appReducer.codifications);
  const codificationsTitre = useSelector(store => store.appReducer.codificationsTitre);

  const setAutoComplete = (event, type) => {
    switch (type) {
      case 'cp':
        setCp(event.target.value);
        break;
      case 'activite':
        setActivite(event.target.value);
        break;
      default:
        return null;
    }
  };

  const handleData = type => {
    switch (type) {
      case 'cp':
        return cp !== '' &&
          codesPostaux &&
          codesPostaux.data !== null &&
          codesPostaux.data.body &&
          Array.isArray(codesPostaux.data.body)
          ? codesPostaux.data.body
          : [];
      case 'activite':
        return activite &&
          activite.length >= 3 &&
          activiteRedux &&
          activiteRedux.data !== null &&
          activiteRedux.data.body &&
          Array.isArray(activiteRedux.data.body)
          ? activiteRedux.data.body
          : [];
      case 'reseau':
        if (
          formReducer &&
          formReducer.departement &&
          formReducer.departement.data &&
          formReducer.departement.data.length === 1
        ) {
          return [];
        }
        return referentiel &&
          referentiel.data !== null &&
          referentiel.data.body &&
          Array.isArray(referentiel.data.body) &&
          referentiel.data.body.length !== 0
          ? referentiel.data.body
          : [];
      case 'city':
        return cityRedux && cityRedux.data !== null && cityRedux.data.body && Array.isArray(cityRedux.data.body)
          ? cityRedux.data.body
          : [];
      default:
        return [];
    }
  };

  const getValue = (event, value, type) => {
    switch (type) {
      case 'cp':
        if (value) {
          setCp(value);
        } else {
          setCp('');
        }
        break;
      case 'reseau':
        if (value) {
          setReseau(value.structureMembre);
        } else if (
          !value ||
          (formReducer &&
            formReducer.departement &&
            formReducer.departement.data &&
            formReducer.departement.data.length === 1)
        ) {
          setReseau('');
        }
        break;
      case 'activite':
        if (value) {
          setActivite(value);
        } else {
          setActivite('');
        }
        break;
      case 'city':
        if (value) {
          setCity(value.nom);
        } else {
          setCity('');
        }
        break;
      default:
        return null;
    }
  };

  const handleClasses = () => {
    switch (props.styleClasse) {
      case 'select':
        return classes.select;
      case 'textfield':
        return classes.textField;
      default:
        return null;
    }
  };

  const handleOption = option => {
    if (option.libelleNAF) {
      return option.libelleNAF;
    } else if (option.value) {
      return option.value;
    } else if (option.structureMembre) {
      return option.structureMembre;
    } else if (option.nom) {
      return option.nom;
    }
    return '';
  };

  useEffect(() => {
    if (cp && cp.length === 2 && props.step === 4) {
      dispatch(appActions.get_codes_postaux(cp));
    }
  }, [cp, props.step, dispatch]);

  useEffect(() => {
    if (reseau !== null && props.step === 1) dispatch(appActions.data_form(reseau, 'reseau'));
  }, [reseau, props.step, dispatch]);

  useEffect(() => {
    if (cp !== null && props.step === 4) {
      dispatch(appActions.data_form(cp, props.type));
    }
  }, [cp, props.type, props.step, dispatch]);

  useEffect(() => {
    if (activite !== null && props.step === 2) {
      dispatch(appActions.data_form(activite, props.type));
    }
  }, [activite, props.type, props.step, dispatch]);

  useEffect(() => {
    if (city !== null) {
      dispatch(appActions.data_form(city, props.type));
    }
  }, [city, props.type, dispatch]);

  useEffect(() => {
    if (activite && activite.length === 3 && props.step === 2) {
      dispatch(appActions.post_referentiel_activite({ libelle: activite }));
    }
  }, [activite, props.step, dispatch]);

  useEffect(() => {
    if (cp !== null && cp.value && cp.value.length === 5) {
      dispatch(appActions.get_city_by_cp(cp && cp.value));
    }
  }, [cp, dispatch]);

  let commentaire = null;
  let reseauNum = null;
  if (
    reseau !== null &&
    props.step === 1 &&
    formReducer &&
    formReducer.departement &&
    formReducer.departement.data &&
    formReducer.departement.data.length === 2
  ) {
    commentaire =
      referentiel &&
      referentiel.data &&
      referentiel.data.body.find(referentiel => referentiel.structureMembre === reseau);
    reseauNum = commentaire;
    if (typeof commentaire !== 'undefined') {
      commentaire = commentaire && commentaire.informations;
    }
  }
  useEffect(() => {
    if (typeof commentaire !== 'undefined' && reseau) {
      dispatch(appActions.data_form(reseauNum, 'numeroReseau'));
    }
  }, [reseau, reseauNum, commentaire, dispatch]);

  const returnDefaultvalue = type => {
    if (type === 'reseau') {
      if (typeof props.defaultValue !== 'undefined') {
        return { value: props.defaultValue };
      } else {
        return null;
      }
    }

    if (type === 'city') {
      if (typeof props.defaultValue !== 'undefined') {
        return { value: props.defaultValue };
      } else {
        return null;
      }
    }

    if (typeof props.defaultValue === 'object') {
      return props.defaultValue;
    } else {
      return null;
    }
  };

  return (
    <div>
      {props.type === 'activite' ? (
        <div>
          <MuiThemeProvider theme={theme}>
            <Tooltip
              interactive
              placement="left"
              title={handleCodificationsInfobulles('ACTIVITE_PRINCIPALE', codifications, codificationsTitre)}
              disableHoverListener={
                handleCodificationsInfobulles('ACTIVITE_PRINCIPALE', codifications, codificationsTitre) === false
              }
              disableFocusListener={
                handleCodificationsInfobulles('ACTIVITE_PRINCIPALE', codifications, codificationsTitre) === false
              }
            >
              <Autocomplete
                id={props.id}
                options={handleData(props.type)}
                noOptionsText=""
                value={typeof props.defaultValue === 'object' ? props.defaultValue : null}
                getOptionLabel={option => handleOption(option)}
                onChange={(e, value) => getValue(e, value, props.type)}
                getOptionSelected={(option, value) => option.iso === value.iso}
                renderInput={params => (
                  <MuiThemeProvider theme={color}>
                    <TextField
                      {...params}
                      onChange={e => setAutoComplete(e, props.type)}
                      variant="outlined"
                      className={handleClasses()}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: props.type === 'activite' && (
                          <React.Fragment>
                            {activite && activite.length >= 3 && activiteRedux && activiteRedux.data === null ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                    {props.type === 'activite' &&
                      formReducer &&
                      formReducer.activite &&
                      formReducer.activite.data &&
                      formReducer.activite.data.commentaires && (
                        <Grid item xs={12} sm={10} md={8}>
                          <Alert icon={false} className={classes.alert}>
                            <p
                              className={classes.infosAlert}
                              dangerouslySetInnerHTML={{
                                __html:
                                  formReducer &&
                                  formReducer.activite &&
                                  formReducer.activite.data &&
                                  formReducer.activite.data.commentaires
                              }}
                            ></p>
                          </Alert>
                        </Grid>
                      )}
                    {props.type === 'activiteSecondary' &&
                      formReducer &&
                      formReducer.activiteSecondary &&
                      formReducer.activiteSecondary.data &&
                      formReducer.activiteSecondary.data.commentaires && (
                        <Grid item xs={12} sm={10} md={8}>
                          <Alert icon={false} className={classes.alert}>
                            <p
                              className={classes.infosAlert}
                              dangerouslySetInnerHTML={{
                                __html:
                                  formReducer &&
                                  formReducer.activiteSecondary &&
                                  formReducer.activiteSecondary.data &&
                                  formReducer.activiteSecondary.data.commentaires
                              }}
                            ></p>
                          </Alert>
                        </Grid>
                      )}
                  </MuiThemeProvider>
                )}
              />
            </Tooltip>
          </MuiThemeProvider>
        </div>
      ) : (
        <Autocomplete
          id={props.id}
          options={handleData(props.type)}
          noOptionsText=""
          value={returnDefaultvalue(props.type)}
          getOptionLabel={option => handleOption(option)}
          onChange={(e, value) => getValue(e, value, props.type)}
          getOptionSelected={(option, value) => option.iso === value.iso}
          renderInput={params => {
            const inputProps = params.inputProps;
            inputProps.autoComplete = 'new-password';
            return (
              <TextField
                {...params}
                inputProps={inputProps}
                onChange={e => setAutoComplete(e, props.type)}
                variant="outlined"
                autoComplete="new-password"
                className={handleClasses()}
              />
            );
          }}
        />
      )}
    </div>
  );
}

TextFieldAutoComplete.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.string,
  step: PropTypes.number,
  styleClasse: PropTypes.string,
  justify: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
