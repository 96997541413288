import Card from "@material-ui/core/Card";
import React from "react";

export const StickyAlert = () => (
  <Card
    style={{
      backgroundColor: "#E85211",
      padding: "8px 16px",
      color: "#FFF",
      fontFamily: "Work Sans",
      position: "sticky",
      top: "0px",
      marginLeft: "32px",
      marginRight: "32px",
      zIndex: "999"
    }}
  >
    EDLC est en cours de changement d’assureur sur les contrats de multirisques
    professionnelles et, compte tenu de motifs réglementaires, la réalisation de
    devis est suspendue pour ce contrat dès aujourd’hui.
    La souscription sera suspendue du 31/08/2024 au 30/09/2024.
    Nous pourrons reprendre cette activité à compter du 01 octobre.
  </Card>
);
