import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import appActions from 'redux/actions/appActions';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export default function Checkbox(props) {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(props.defaultValue ? props.defaultValue : props.secondaryLabel);

  const handleChange = e => {
    setChecked(e.target.value);
  };

  const theme = createMuiTheme({
    palette: {
      primary: { main: '#E85313' }
    }
  });

  useEffect(() => {
    dispatch(appActions.data_form(checked, props.type));
  }, [checked, props.type, dispatch]);

  return (
    <MuiThemeProvider theme={theme}>
      <FormControl component="fieldset">
        <RadioGroup
          style={{ color: 'primary' }}
          row
          aria-label="position"
          name="position"
          value={typeof props.defaultValue !== 'undefined' && props.defaultValue}
          onChange={handleChange}
        >
          <FormControlLabel
            value={props.firstLabel}
            control={<Radio color="primary" />}
            label={props.firstLabel}
            labelPlacement="end"
          />
          <FormControlLabel
            value={props.secondaryLabel}
            control={<Radio color="primary" />}
            label={props.secondaryLabel}
            labelPlacement="end"
          />
        </RadioGroup>
      </FormControl>
    </MuiThemeProvider>
  );
}

Checkbox.propTypes = {
  firstLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.string
};
