import { combineReducers } from 'redux';
import appReducer from './appReducer';
import formReducer from './formReducer';

const rootReducer = combineReducers({
  appReducer,
  formReducer
});

export default rootReducer;
