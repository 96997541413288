import Step2 from 'views/Step2/Step2.js';
import Step3 from 'views/Step3/Step3.js';
import Tarification from 'views/Tarification/Tarification';
import Finalisation from 'views/Finalisation/Finalisation';
import SendMail from 'views/SendMail/SendMail';
import Step1 from 'views/Step1/Step1';

const routes = [
  {
    path: 'step-1',
    name: 'Step1',
    component: Step1,
    layout: '/'
  },
  {
    path: 'step-2',
    name: 'Step2',
    component: Step2,
    layout: '/'
  },
  {
    path: 'step-3',
    name: 'Step3',
    component: Step3,
    layout: '/'
  },
  {
    path: 'tarification',
    name: 'Tarification',
    component: Tarification,
    layout: '/'
  },
  {
    path: 'finalisation',
    name: 'Finalisation',
    component: Finalisation,
    layout: '/'
  },
  {
    path: 'send-mail',
    name: 'SendMail',
    component: SendMail,
    layout: '/'
  }
];

export default routes;
