import { GET_DATA_FORM_FAIL, GET_DATA_FORM_REQUEST, GET_DATA_FORM_SUCCESS } from '../actionTypes';

const initialState = {};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DATA_FORM_REQUEST:
      return {
        ...state,
        [action.form]: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    case GET_DATA_FORM_SUCCESS:
      return {
        ...state,
        [action.form]: {
          isLoading: false,
          error: false,
          data: action.data
        }
      };
    case GET_DATA_FORM_FAIL:
      return {
        ...state,
        [action.form]: {
          isLoading: false,
          error: action.payload,
          data: false
        }
      };
    default:
      return state;
  }
}
