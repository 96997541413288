// every key in this config object has two values:
// - platform: The "___VALUE__" is a placeholder.
//             It will be replaced at the deployment according to the target platform
// - default:  The value to use if the platform value is still a placeholder.
//             For example in development
const config = {
  apiUrl: {
    platform: "___API_CORE_URL___",
    default:
      "https://ppr-core-api.v4.app.entrepreneursdelacite.org/edlcCore/api"
  }
};

export function getConfig(...keys) {
  let key;
  let value = config;
  while ((key = keys.shift())) {
    if (!value[key]) {
      throw new Error("Unknown platform config key: " + key);
    }
    value = value[key];
  }

  if (value.platform.startsWith("___")) {
    return value.default;
  }

  return value.platform;
}
