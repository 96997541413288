import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { getValueStock } from 'utils';

import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import img from 'assets/img/group-3.png';

const useStyles = makeStyles({
  card: {
    height: '100%',
    backgroundColor: '#E85211'
  },
  cardFinal: {
    height: '100%',
    backgroundColor: '#E85211',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: '75%',
    overflowX: 'hidden'
  },
  title: {
    fontSize: 32,
    color: '#FFFFFF',
    fontFamily: 'Work Sans',
    maxWidth: 341,
    fontWeight: 900,
    letterSpacing: 0
  },
  back: {
    color: '#FFFFFF',
    fontFamily: 'Work Sans',
    fontSize: 14,
    fontWeight: 500,
    maxWidth: 341,
    letterSpacing: 0,
    lineHeight: 16,
    display: 'flex',
    alignItems: 'center'
  },
  infos: {
    fontSize: 16,
    color: '#FFFFFF',
    maxWidth: 341,
    letterSpacing: 0,
    marginTop: 56,
    fontFamily: 'Work Sans'
  },
  img: {
    maxWidth: 600
  },
  go: {
    fontWeight: 600
  },
  backMobile: {
    marginLeft: 10,
    marginTop: 25,
    display: 'flex',
    alignItems: 'center',
    width: 54.7,
    height: 16,
    fontFamily: 'Work Sans',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#ffffff'
  },
  titleMobile: {
    marginLeft: 16,
    marginTop: 23,
    display: 'flex',
    alignItems: 'center',
    width: 288,
    height: 90,
    fontFamily: 'Work Sans',
    fontSize: 25,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    color: '#ffffff'
  },
  infosMobile: {
    marginLeft: 16,
    marginBottom: 41,
    width: 288,
    height: 156,
    fontFamily: 'Work Sans',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.63,
    letterSpacing: 'normal',
    color: '#ffffff'
  },
  récapitulatif: {
    fontFamily: 'WorkSans',
    fontSize: 22,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#ffffff',
    marginTop: 56,
    marginBottom: 33
  },
  questions: {
    fontFamily: 'Work Sans',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#ffffff'
  },
  responses: {
    fontFamily: 'Work Sans',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#ffffff'
  }
});

export default function Panel(props) {
  const classes = useStyles();

  const dataForm = useSelector(store => store.formReducer);
  const codificationsMenu = useSelector(store => store.appReducer.codificationsMenu);

  const codifs = codificationsMenu && codificationsMenu.data && codificationsMenu.data.body;
  let data = [];

  if (props.step === 1) {
    data = [codifs && codifs[0], codifs && codifs[1], codifs && codifs[2]];
  } else if (props.step === 2) {
    data = [codifs && codifs[3], codifs && codifs[4], codifs && codifs[5]];
  } else if (props.step === 3) {
    data = [codifs && codifs[6], codifs && codifs[7], codifs && codifs[8]];
  }

  return (
    <Card className={classes.card}>
      {props.step === 4 && (
        <Grid container direction="row" justify="center" alignItems="flex-start">
          <Grid item xs={12} sm={8} className={classes.récapitulatif}>
            Récapitulatif
          </Grid>
          <Grid item xs={12} sm={8} className={classes.questions}>
            <p>Avez vous été suivi par un réseau partenaire lors de la création ?</p>
            <p className={classes.responses}>
              {dataForm.reseauBoolean && dataForm.reseauBoolean.data + '.'}
              {dataForm.reseau && dataForm.reseau.data}
            </p>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.questions}>
            <p>Êtes vous suivi AGEFIPH ?</p>
            <p className={classes.responses}>{dataForm.agefiph && dataForm.agefiph.data}</p>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.questions}>
            <p>Date de création de l’entreprise</p>
            <p className={classes.responses}>{dataForm.created && dataForm.created.data}</p>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.questions}>
            <p>Société en cours de création</p>
            <p className={classes.responses}>{dataForm.beingCreated && dataForm.beingCreated.data}</p>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.questions}>
            <p>Activité</p>
            <p className={classes.responses}>
              {dataForm.activite && dataForm.activite.data && dataForm.activite.data.libelleNAF}
            </p>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.questions}>
            <p>Avez-vous un local ?</p>
            <p className={classes.responses}>{dataForm.local && dataForm.local.data}</p>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.questions}>
            <p>Montant stock & materiel à assurer</p>
            <p className={classes.responses}>{dataForm.valueStock && getValueStock(dataForm.valueStock.data)}</p>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.questions}>
            <p>Chiffre d’Affaires estimé (annuel en €)</p>
            <p className={classes.responses}>{dataForm.turnover && dataForm.turnover.data + ' €'}</p>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.questions}>
            <p>Produits choisis</p>
            <p className={classes.responses}>
              {dataForm.tarif &&
                dataForm.tarif.data &&
                dataForm.tarif.data.map(data => (
                  <span style={{ margin: 2 }} key={data.libelle}>
                    {data.libelle},
                  </span>
                ))}
            </p>
          </Grid>
        </Grid>
      )}
      {props.step !== 4 && props.step !== 5 && (
        <div>
          <Hidden only="xs">
            <Grid container direction="row" justify="center" alignItems="flex-start">
              {props.step === 1 && (
                <Grid item sm={10} md={10} lg={12} className={classes.back}>
                  <ChevronLeftIcon />
                  <Link
                    href="https://www.entrepreneursdelacite.org"
                    style={{ color: '#FFFFFF', textDecoration: 'none' }}
                  >
                    <p style={{ cursor: 'pointer' }}>Retour au site</p>
                  </Link>
                </Grid>
              )}
              <Grid
                item
                sm={10}
                md={10}
                lg={12}
                className={classes.title}
                style={{ marginTop: props.step !== 1 && 190 }}
                dangerouslySetInnerHTML={{
                  __html: data && data[0] && data[0].libelle
                }}
              ></Grid>
              <Grid item sm={10} md={10} lg={12} className={classes.infos}>
                <p
                  className={classes.go}
                  dangerouslySetInnerHTML={{
                    __html: data && data[1] && data[1].libelle
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data && data[2] && data[2].libelle
                  }}
                ></p>
              </Grid>
              <img src={img} alt="group" />
            </Grid>
          </Hidden>

          <Hidden only={['md', 'sm', 'lg', 'xl']}>
            <Grid container direction="row" justify="center" alignItems="flex-start">
              {props.step === 1 && (
                <Grid item xs={12} sm={10} md={10} lg={12} className={classes.backMobile}>
                  <ChevronLeftIcon />
                  <p>Retour au site</p>
                </Grid>
              )}
              <Grid item xs={12} sm={10} md={10} lg={12} className={classes.titleMobile}>
                Faîtes-vous <br />
                accompagner dans <br />
                votre nouvelle vie !
              </Grid>
              <Grid item xs={12} sm={10} md={10} lg={12} className={classes.infosMobile}>
                <p className={classes.goMobile}>C’est parti !</p>
                <p>
                  Quelques infos sur vous et votre projet pour commencer. Cela ne vous prendra que quelques minutes.
                </p>
              </Grid>
            </Grid>
          </Hidden>
        </div>
      )}

      {props.step === 5 && (
        <div>
          <Hidden only="xs">
            <Grid container direction="row" justify="center" alignItems="flex-start">
              <Grid item sm={10} md={10} lg={12} className={classes.title} style={{ marginTop: 150 }}>
                Faîtes-vous <br />
                accompagner dans votre nouvelle vie !
              </Grid>
              <Grid item sm={10} md={10} lg={12} className={classes.infos}>
                <p className={classes.go}>Merci pour votre confiance ! </p>
              </Grid>
              <img src={img} alt="group" />
            </Grid>
          </Hidden>
        </div>
      )}
    </Card>
  );
}

Panel.propTypes = {
  step: PropTypes.number,
  history: PropTypes.object,
  final: PropTypes.bool
};
