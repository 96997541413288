import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';

import './style.css';

export default function SquareTarification(props) {
  const useStyles = makeStyles({
    container: {
      height: '100%',
      width: '100%'
    },
    square: {
      minWidth: 190,
      height: 350,
      borderRadius: 8,
      border: 'solid 3px #e3e3e3',
      backgroundColor: '#ffffff',
      marginLeft: 5
    },
    text: {
      fontFamily: 'Work Sans',
      fontSize: 21,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#4a4a49'
    },
    price: {
      fontFamily: 'Work Sans',
      fontSize: 56,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#e85211',
      margin: 0
    },
    month: {
      opacity: 0.8,
      fontFamily: 'Work Sans',
      fontSize: 20,
      fontWeight: 300,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#4a4a49'
    },
    check: {
      fontFamily: 'Work Sans',
      fontSize: 14,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      marginTop: 40,
      height: 42,
      borderRadius: 8,
      backgroundColor: '#E85313',
      color: '#ffff'
    },
    alert: {
      width: '30%',
      minWidth: 80,
      height: 30,
      borderRadius: 15,
      marginTop: 19,
      backgroundColor: '#fff8f5',
      textAlign: 'center'
    },
    link: {
      height: 16,
      opacity: 0.8,
      fontFamily: 'Work Sans',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#4a4a49',
      textDecoration: 'underline',
      marginBottom: 25
    },
    textLink: {
      color: '#4a4a49',
      textDecoration: 'underline'
    }
  });

  const classes = useStyles();

  const formReducer = useSelector(store => store.formReducer);

  const findTarification = () => {
    if (
      formReducer &&
      formReducer.tarif &&
      Array.isArray(formReducer.tarif.data) &&
      formReducer.tarif.data.length > 0
    ) {
      const findIndex =
        formReducer &&
        formReducer.tarif &&
        Array.isArray(formReducer.tarif.data) &&
        formReducer.tarif.data.length > 0 &&
        formReducer.tarif.data.findIndex(data => data.code === props.tarif.code);
      if (findIndex !== -1 && typeof findIndex !== 'undefined') {
        return true;
      } else if (findIndex === -1) {
        return false;
      }
    } else {
      return false;
    }
  };

  const getDocumentByCategorie = (categorie, documents) => {
    const result = documents.filter(data => data.categorie === categorie);

    if (result.length > 0) {
      return result[0];
    }

    return undefined;
  };

  const returnGrid = documents => {
    if (documents && documents.length <= 2) {
      return 6;
    } else if (documents && documents.length <= 1) {
      return 12;
    } else if (documents && documents.length <= 3) {
      return 4;
    }
  };

  const getDocumentNode = (libelle, document) => {
    if (!document) return null;
    return (
      <Grid
        container
        key={libelle}
        item
        xs={returnGrid(props.tarif && props.tarif.documents)}
        sm={returnGrid(props.tarif && props.tarif.documents)}
        justify="center"
        className={classes.link}
      >
        <p style={{ textAlign: 'center' }}>
          <a className={classes.textLink} href={document.url}>
            {libelle}
          </a>
        </p>
      </Grid>
    );
  };

  const [click, setClick] = useState(findTarification());

  const onCLick = () => {
    if (props.tarif && props.tarif.montantMensuel !== 0) {
      if (click) {
        setClick(false);
        props.retrievePrice(props.tarif, 'delete');
      } else {
        props.retrievePrice(props.tarif);
        setClick(true);
      }
    }
  };

  return (
    <div className={classes.container} style={{ marginBottom: 80 }}>
      <Card className={classes.square} style={{ borderColor: findTarification() && '#E85313' }}>
        <Grid container justify="center" alignItems="flex-start">
          <Grid item xs={12} sm={12}>
            <p className={classes.text}>{props.tarif && props.tarif.libelle}</p>
          </Grid>
          <Grid item xs={12} sm={12}>
            <p className={props.tarif && props.tarif.montantMensuel === 0 ? classes.text : classes.price}>
              {props.tarif && props.tarif.montantMensuel === 0
                ? props.tarif && props.tarif.message
                : props.tarif && props.tarif.montantMensuel}
              {props.tarif && props.tarif.montantMensuel !== 0 && '€'}
            </p>
            <p className={classes.month}>{props.tarif && props.tarif.montantMensuel !== 0 && 'TTC / mois'}</p>
            <p className={classes.month}>{props.tarif && props.tarif.descriptionTaxe && props.tarif.descriptionTaxe}</p>
            <Grid container item xs={12} sm={12} alignItems="flex-start" justify="center">
              <Button onClick={onCLick} variant="contained" className={classes.check}>
                Choisir
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      {props.tarif && Array.isArray(props.tarif.documents) && (
        <Grid container>
          {getDocumentNode(
            'Synthèse des garanties',
            getDocumentByCategorie('SYNTHESE_GARANTIE', props.tarif.documents)
          )}
          {getDocumentNode(
            'Conditions Générales',
            getDocumentByCategorie('CONDITIONS_GENERALES', props.tarif.documents)
          )}
          {getDocumentNode('Fiche Produit', getDocumentByCategorie('FICHE_PRODUIT', props.tarif.documents))}
        </Grid>
      )}

      {props.tarif && props.tarif.montantMensuel !== 0 && (
        <Grid item xs={12} sm={12} style={{ textAlign: '-webkit-center', marginBottom: 50 }}>
          <Alert icon={false} className={classes.alert}>
            {props.tarif && props.tarif.montantAnnuel}€/an
          </Alert>
        </Grid>
      )}
    </div>
  );
}

SquareTarification.propTypes = {
  tarif: PropTypes.object,
  retrievePrice: PropTypes.func,
  index: PropTypes.number
};
