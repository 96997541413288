import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appActions from 'redux/actions/appActions';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import logo from 'assets/img/bi-gout.png';
import img from 'assets/img/group-20.png';
import Panel from 'components/Panel/Panel';

import Button from '@material-ui/core/Button';

import Link from '@material-ui/core/Link';
import { CardWebinaire } from './CardWebinaire';

const useStyles = makeStyles({
  grid: {
    height: '100vh',
    overflowY: 'none'
  },
  img: {
    marginBottom: 16,
    marginTop: 16,
    marginLeft: 16,
    width: 35
  },
  infos: {
    fontSize: 20,
    letterSpacing: 0,
    fontFamily: 'Work Sans',
    textAlign: 'flex-start'
  },
  logo: {
    marginBottom: 16,
    marginTop: 16,
    marginLeft: 40
  },
  paragraphe: {
    fontFamily: 'Work Sans',
    fontSize: 16,
    letterSpacing: 0,
    textAlign: 'center',
    color: '#4a4a49'
  },
  span: {
    color: '#e85211'
  },
  validation: {
    color: '#4A4A49',
    fontFamily: 'Work Sans',
    fontSize: 22,
    fontWeight: 'bold',
    letterSpacing: 0
  },
  check: {
    height: 32,
    color: '#5DD29D',
    alignItems: 'center'
  },
  text: {
    color: '#FFFFFF',
    fontFamily: 'Work Sans',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center'
  },
  helpColor: {
    color: '#E85211',
    fontFamily: 'Work Sans',
    fontSize: 32,
    fontWeight: 900,
    letterSpacing: 0,
    textAlign: 'center'
  },
  help: {
    fontFamily: 'Work Sans',
    fontSize: 32,
    fontWeight: 900,
    letterSpacing: 0,
    textAlign: 'center'
  },
  back: {
    fontFamily: 'Work Sans',
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    height: 42,
    borderRadius: 8,
    backgroundColor: '#6467CE',
    color: '#ffff',
    margin: '48px 0'
  },
  separator: {
    backgroundColor: 'black',
    width: 700,
    height: 1,
    opacity: '17%',
    margin: '48px 0'
  }
});

/**
 * @type { {title: string, jour: string, horaire: string, link: string, recurrence?: string}[] }
 */
const webinaires = [
  {
    title: 'La responsabilité civile',
    jour: '9 janvier 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/webinaire-edlc-la-responsabilite-civile/live?s=9dbb5399-d15d-49f4-aa5a-50e8b8c7981a#/'
  },
  {
    title: 'Les risque de l\'entreprise',
    jour: '16 janvier 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/w/live?s=fb9deccc-16b7-43c8-af0e-1c1d80ac170f#/'
  },
  {
    title: 'La protection juridique',
    jour: '23 janvier 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/webinaire-edlc-la-protection-juridique/live?s=f73243dc-9a1d-4499-82b1-786fc4f0e70f#/'
  },
  {
    title: "Éviter l'épuisement professionnel",
    jour: '30 janvier 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/webinaire-edlc-comment-eviter-lepuisement-professionnel/live?s=ec9e6344-8405-4d90-9c5f-5b9bd6cd3bdc#/'
  },
  {
    title: 'Les impayés',
    jour: '6 février 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/webinaire-edlc-les-impayes/live?s=54537af1-2983-4d84-b2bb-9f14ab880de9#/'
  },
  {
    title: 'Prévenir les difficultés financières',
    jour: '6 mars 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/webinaire-edlc-prevenir-les-difficultes-financieres-ll-importance-de-se-faire-accompagner/live?s=32f6c498-8254-4fbf-9bb3-843c7ecf9cb0#/'
  },
  {
    title: 'Les risques cyber',
    jour: '13 mars 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/webinaire-edlc-les-risques-cyber/live?s=7794648a-2a92-4662-bfcd-fa9b9b632408#/'
  },
  {
    title: 'La garantie décennale',
    jour: '20 mars 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/webinaire-edlc-la-garantie-decennale/live?s=ea44d181-4204-447e-b70a-94a2f8cd336d#/'
  },
  {
    title: 'La protection sociale',
    jour: '27 mars 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/webinaire-edlc-la-protection-sociale/live?s=daa20bf2-ef52-462c-b1c6-b7e2ba46404b#/'
  },
  {
    title: 'Comprendre et préparer sa retraite',
    jour: '3 avril 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/webinaire-edlc-comprendre-et-preparer-sa-retraite/live?s=6369f96c-a72c-488b-9b87-e7bbe6666317#/'
  },
  {
    title: 'Les risque de l\'entreprise',
    jour: '15 mai 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/w/live?s=f97b6051-c107-4d2d-be18-827a44e14a2d#/'
  },
  {
    title: 'La protection juridique',
    jour: '22 mai 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/webinaire-edlc-la-protection-juridique/live?s=84fca212-5ece-4cdf-8cbd-decfb48eec82#/'
  },
  {
    title: 'Les impayés',
    jour: '5 juin 2025',
    horaire: 'de 14h30 à 15h30',
  },
  {
    title: 'La protection sociale',
    jour: '12 juin 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/webinaire-edlc-la-protection-sociale/live?s=4f8432dd-fd34-45e5-a2bc-2dfb40e9b482#/'
  },
  {
    title: 'Prévenir les difficultés financières',
    jour: '19 juin 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/webinaire-edlc-prevenir-les-difficultes-financieres-ll-importance-de-se-faire-accompagner/live?s=be7ed7c4-be8a-46b1-876d-af07e6f5a357#/'
  },
  {
    title: 'La responsabilité civile',
    jour: '26 juin 2025',
    horaire: 'de 14h30 à 15h30',
    link: 'https://app.livestorm.co/france-active/webinaire-edlc-la-responsabilite-civile/live?s=9ed7578a-ed95-41c0-bd68-80b01dd7b137#/'
  }
];

export default function SendMail(props) {
  const classes = useStyles();
  const formReducer = useSelector(store => store.formReducer);
  const create = useSelector(store => store.appReducer.prospect);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.data_form(null, 'jwt'));
  }, [dispatch]);

  return (
    <Grid className={classes.grid} container justify="flex-end">
      <Hidden only={'xs'}>
        <Grid item xs={12} sm={4} lg={4} xl={3}>
          <Panel step={5} history={props.history} />
        </Grid>
      </Hidden>
      {create.isLoading ? (
        <Grid container item xs={12} sm={8} lg={8} xl={9} justify="center" alignContent="center">
          <CircularProgress style={{ color: '#E85313' }} size={50} />
        </Grid>
      ) : (
        <Grid container item xs={12} sm={8} lg={8} xl={9} justify="center">
          <Grid item xs={12} sm={9} className={classes.logo}>
            <img src={logo} alt="logo" />
          </Grid>

          <Grid container item xs={12} sm={12} justify="center">
            <img className={classes.img} src={img} alt="umbrella" />
          </Grid>

          <Grid item xs={12} sm={12}>
            <p className={classes.paragraphe}>
              <CheckCircleIcon
                style={{
                  color: '#5DD29D',
                  verticalAlign: 'middle',
                  marginRight: 10,
                  fontSize: 35
                }}
              />
              <span className={classes.validation}>Votre demande a été prise en compte</span>
              <br />
              <p style={{ marginTop: 16 }}>
                Vous allez recevoir votre devis dans les plus brefs délais par mail à l’adresse{' '}
                <span className={classes.span}>{formReducer && formReducer.mail && formReducer.mail.data}</span>
              </p>
            </p>
          </Grid>
          <Grid container justify="center">
            <div className={classes.separator}></div>
          </Grid>
          <Grid container justify="center" style={{ marginBottom: 48 }}>
            <span className={classes.validation}>Découvrez nos formations webinaires</span>
          </Grid>
          <Grid container justify="center" style={{ width: 700 }}>
            {webinaires.map((webinaire, index) => (
              <CardWebinaire key={index} webinaire={webinaire} />
            ))}
          </Grid>
          <Grid container item xs={12} sm={12} justify="center">
            <Link
              href="https://www.entrepreneursdelacite.org/nos-solutions-et-services"
              style={{ color: '#FFFFFF', textDecoration: 'none' }}
            >
              <Button className={classes.back} variant="contained">
                Retour au site
              </Button>
            </Link>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

SendMail.propTypes = {
  history: PropTypes.object
};
