import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import routes from 'routes.js';

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === '/') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      }
      return null;
    })}
    <Redirect from="/" to="/step-1" />
  </Switch>
);

export default function Routes() {
  return <div>{switchRoutes}</div>;
}
