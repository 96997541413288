import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Badge from '@material-ui/core/Badge';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    float: 'left',
    padding: '0px !important',
    marginTop: 70
  },
  rootXs: {
    maxWidth: '80%',
    padding: '0px !important',
    margin: '30px auto'
  },
  rootSm: {
    maxWidth: '100%',
    padding: '0px !important',
    marginTop: 96
  },
  paragraphe: {
    fontFamily: 'Work Sans',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#323232'
  },
  paragrapheMobile: {
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#323232'
  },
  circle: {
    borderRadius: '50%',
    height: 10,
    width: 10,
    padding: 10,
    background: '#ffff',
    border: 'solid 2px #e85211',
    color: '#000',
    textAlign: 'center',
    display: 'inline-block',
    margin: 10
  },
  number: {
    width: 7,
    height: 19,
    fontFamily: 'Work Sans',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center !important',
    color: '#323232'
  }
}));

const StyledBadgeOn = withStyles(theme => ({
  badge: {
    height: 24,
    width: 24,
    borderRadius: '50%',
    border: `2px solid #e85211`,
    padding: '0 4px',
    color: '#323232',
    fontFamily: 'Work Sans',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center'
  }
}))(Badge);

const StyledBadgeOff = withStyles(theme => ({
  badge: {
    height: 24,
    width: 24,
    borderRadius: '50%',
    textAlign: 'center',
    opacity: 0.4,
    padding: '0 4px',
    color: '#323232',
    fontFamily: 'Work Sans',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    border: 'solid 2px #4a4a49'
  }
}))(Badge);

export default function Steppers(props) {
  const classes = useStyles();

  const [name, setName] = useState(null);

  const steps = [
    {
      name: 'Votre projet',
      number: 1
    },
    {
      name: 'Votre activité',
      number: 2
    },
    {
      name: 'Votre foyer',
      number: 3
    }
  ];

  return (
    <div>
      <Hidden only={['xs', 'sm']}>
        <Stepper className={classes.root}>
          {steps.map(data => {
            const Icon = () => {
              return (
                <StepLabel
                  icon={
                    <IconButton aria-label="cart">
                      {(props.step === data.number) | (props.step > data.number) ? (
                        <StyledBadgeOn badgeContent={data.number}></StyledBadgeOn>
                      ) : (
                        <StyledBadgeOff badgeContent={data.number}></StyledBadgeOff>
                      )}
                    </IconButton>
                  }
                  style={{ color: 'red' }}
                >
                  <p className={classes.paragraphe}>{props.step === data.number && data.name}</p>
                </StepLabel>
              );
            };
            return (
              <Step key={data.number}>
                <Icon />
              </Step>
            );
          })}
        </Stepper>
      </Hidden>

      <Hidden only={['xs', 'md', 'lg', 'xl']}>
        <Stepper className={classes.rootSm}>
          {steps.map(data => {
            const Icon = () => {
              return (
                <StepLabel
                  icon={
                    <IconButton aria-label="cart">
                      {(props.step === data.number) | (props.step > data.number) ? (
                        <StyledBadgeOn badgeContent={data.number}></StyledBadgeOn>
                      ) : (
                        <StyledBadgeOff badgeContent={data.number}></StyledBadgeOff>
                      )}
                    </IconButton>
                  }
                  style={{ color: 'red' }}
                >
                  <p className={classes.paragraphe}>{props.step === data.number && data.name}</p>
                </StepLabel>
              );
            };
            return (
              <Step key={data.number}>
                <Icon />
              </Step>
            );
          })}
        </Stepper>
      </Hidden>

      <Hidden only={['md', 'sm', 'lg', 'xl']}>
        <Stepper className={classes.rootXs}>
          {steps.map(data => {
            const Icon = () => {
              if (props.step === data.number && data.name) setName(data.name);
              return (
                <StepLabel
                  icon={
                    <IconButton aria-label="cart">
                      {(props.step === data.number) | (props.step > data.number) ? (
                        <StyledBadgeOn badgeContent={data.number}></StyledBadgeOn>
                      ) : (
                        <StyledBadgeOff badgeContent={data.number}></StyledBadgeOff>
                      )}
                    </IconButton>
                  }
                  style={{ color: 'red' }}
                ></StepLabel>
              );
            };
            return (
              <Step key={data.number}>
                <Icon />
              </Step>
            );
          })}
        </Stepper>
        <p className={classes.paragrapheMobile}>{name}</p>
      </Hidden>
    </div>
  );
}

Steppers.propTypes = {
  step: PropTypes.number
};
