export const POST_REFERENTIEL_RESEAU_FAIL = 'POST_REFERENTIEL_RESEAU_FAIL';
export const POST_REFERENTIEL_RESEAU_REQUEST = 'POST_REFERENTIEL_RESEAU_REQUEST';
export const POST_REFERENTIEL_RESEAU_SUCCESS = 'POST_REFERENTIEL_RESEAU_SUCCESS';

export const GET_CODES_POSTAUX_FAIL = 'GET_CODES_POSTAUX_FAIL';
export const GET_CODES_POSTAUX_REQUEST = 'GET_CODES_POSTAUX_REQUEST';
export const GET_CODES_POSTAUX_SUCCESS = 'GET_CODES_POSTAUX_SUCCESS';

export const POST_REFERENTIEL_ACTIVITE_FAIL = 'POST_REFERENTIEL_ACTIVITE_FAIL';
export const POST_REFERENTIEL_ACTIVITE_REQUEST = 'POST_REFERENTIEL_ACTIVITE_REQUEST';
export const POST_REFERENTIEL_ACTIVITE_SUCCESS = 'POST_REFERENTIEL_ACTIVITE_SUCCESS';

export const GET_CITY_BY_CP_FAIL = 'GET_CITY_BY_CP_FAIL';
export const GET_CITY_BY_CP_REQUEST = 'GET_CITY_BY_CP_REQUEST';
export const GET_CITY_BY_CP_SUCCESS = 'GET_CITY_BY_CP_SUCCESS';

export const GET_DATA_FORM_FAIL = 'GET_DATA_FORM_FAIL';
export const GET_DATA_FORM_REQUEST = 'GET_DATA_FORM_REQUEST';
export const GET_DATA_FORM_SUCCESS = 'GET_DATA_FORM_SUCCESS';

export const GET_VALEUR_STOCK_FAIL = 'GET_VALEUR_STOCK_FAIL';
export const GET_VALEUR_STOCK_REQUEST = 'GET_VALEUR_STOCK_REQUEST';
export const GET_VALEUR_STOCK_SUCCESS = 'GET_VALEUR_STOCK_SUCCESS';

export const POST_PRODUCT_TARFICATION_FAIL = 'POST_PRODUCT_TARFICATION_FAIL';
export const POST_PRODUCT_TARFICATION_REQUEST = 'POST_PRODUCT_TARFICATION_REQUEST';
export const POST_PRODUCT_TARFICATION_SUCCESS = 'POST_PRODUCT_TARFICATION_SUCCESS';

export const POST_PROSPECT_FAIL = 'POST_PROSPECT_FAIL';
export const POST_PROSPECT_REQUEST = 'POST_PROSPECT_REQUEST';
export const POST_PROSPECT_SUCCESS = 'POST_PROSPECT_SUCCESS';

export const GET_CODIFICATIONS_INFOBULLE_FAIL = 'GET_CODIFICATIONS_INFOBULLE_FAIL';
export const GET_CODIFICATIONS_INFOBULLE_REQUEST = 'GET_CODIFICATIONS_INFOBULLE_REQUEST';
export const GET_CODIFICATIONS_INFOBULLE_SUCCESS = 'GET_CODIFICATIONS_INFOBULLE_SUCCESS';

export const GET_CODIFICATIONS_INFOBULLE_TITRE_FAIL = 'GET_CODIFICATIONS_INFOBULLE_TITRE_FAIL';
export const GET_CODIFICATIONS_INFOBULLE_TITRE_REQUEST = 'GET_CODIFICATIONS_INFOBULLE_TITRE_REQUEST';
export const GET_CODIFICATIONS_INFOBULLE_TITRE_SUCCESS = 'GET_CODIFICATIONS_INFOBULLE_TITRE_SUCCESS';

export const GET_CODIFICATIONS_MENU_FAIL = 'GET_CODIFICATIONS_MENU_FAIL';
export const GET_CODIFICATIONS_MENU_REQUEST = 'GET_CODIFICATIONS_MENU_REQUEST';
export const GET_CODIFICATIONS_MENU_SUCCESS = 'GET_CODIFICATIONS_MENU_SUCCESS';
