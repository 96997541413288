import { Button, Link, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyle = makeStyles({
  card: {
    fontFamily: 'Work Sans',
    padding: '16px 24px',
    borderRadius: 8,
    boxShadow: '0 5px 18px #00000014',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    width: '100%'
  },
  highlight: {
    color: '#E85211'
  },
  bold: { fontWeight: 'bold' },
  button: {
    textTransform: 'none',
    borderColor: '#6467CE',
    color: '#6467CE',
    fontWeight: 'bold'
  },
  title: {
    color: '#4A4A49',
    fontSize: 18,
    marginBottom: 4
  },
  date: {
    color: '#4A4A49',
    fontSize: 16
  },
  lienAVenir: {
    display: 'flex',
    alignItems: 'center'
  }
});

export const CardWebinaire = ({ webinaire }) => {
  const classes = useStyle();
  return (
    <div className={classes.card}>
      <div>
        <div className={`${classes.title} ${classes.bold}`}>{webinaire.title}</div>
        <div className={classes.date}>
          Le <span className={`${classes.bold} ${classes.highlight}`}>{webinaire.jour}</span> {webinaire.recurrence} -{' '}
          <span className={classes.highlight}>{webinaire.horaire}</span>
        </div>
      </div>
      {!webinaire.link && (
        <div className={classes.lienAVenir}>
          <span>Lien à venir</span>
        </div>
      )}

      {webinaire.link && (
        <Link href={webinaire.link} target="_blank">
          <Button variant="outlined" className={classes.button}>
            Inscription
          </Button>
        </Link>
      )}

    </div>
  );
};

CardWebinaire.propTypes = {
  webinaire: PropTypes.object
};
