import axios from 'axios';
import {
  POST_REFERENTIEL_RESEAU_FAIL,
  POST_REFERENTIEL_RESEAU_REQUEST,
  POST_REFERENTIEL_RESEAU_SUCCESS,
  GET_CODES_POSTAUX_FAIL,
  GET_CODES_POSTAUX_REQUEST,
  GET_CODES_POSTAUX_SUCCESS,
  POST_REFERENTIEL_ACTIVITE_FAIL,
  POST_REFERENTIEL_ACTIVITE_REQUEST,
  POST_REFERENTIEL_ACTIVITE_SUCCESS,
  GET_CITY_BY_CP_FAIL,
  GET_CITY_BY_CP_REQUEST,
  GET_CITY_BY_CP_SUCCESS,
  GET_DATA_FORM_FAIL,
  GET_DATA_FORM_REQUEST,
  GET_DATA_FORM_SUCCESS,
  GET_VALEUR_STOCK_FAIL,
  GET_VALEUR_STOCK_REQUEST,
  GET_VALEUR_STOCK_SUCCESS,
  POST_PRODUCT_TARFICATION_FAIL,
  POST_PRODUCT_TARFICATION_REQUEST,
  POST_PRODUCT_TARFICATION_SUCCESS,
  POST_PROSPECT_FAIL,
  POST_PROSPECT_REQUEST,
  POST_PROSPECT_SUCCESS,
  GET_CODIFICATIONS_INFOBULLE_FAIL,
  GET_CODIFICATIONS_INFOBULLE_REQUEST,
  GET_CODIFICATIONS_INFOBULLE_SUCCESS,
  GET_CODIFICATIONS_INFOBULLE_TITRE_FAIL,
  GET_CODIFICATIONS_INFOBULLE_TITRE_REQUEST,
  GET_CODIFICATIONS_INFOBULLE_TITRE_SUCCESS,
  GET_CODIFICATIONS_MENU_FAIL,
  GET_CODIFICATIONS_MENU_REQUEST,
  GET_CODIFICATIONS_MENU_SUCCESS
} from '../actionTypes';

import { getConfig } from 'platform';

const dispatch_success = (data, success, form) => {
  return {
    type: success,
    data,
    form: form && form
  };
};

const dispatch_request = (data, request, form) => {
  return {
    type: request,
    data,
    form: form && form
  };
};

const dispatch_fail = (data, fail, form) => {
  return {
    type: fail,
    data,
    form: form && form
  };
};

const post_referentiel_reseau = data => {
  return dispatch => {
    dispatch({ type: POST_REFERENTIEL_RESEAU_REQUEST });
    return axios
      .post(`${getConfig('apiUrl')}/referentiel/reseau/lister`, data)
      .then(response => {
        dispatch(dispatch_success(response.data, POST_REFERENTIEL_RESEAU_SUCCESS));
      })
      .catch(error => {
        dispatch({ type: POST_REFERENTIEL_RESEAU_FAIL, payload: error });
      });
  };
};

const post_product_tarification = data => {
  return dispatch => {
    dispatch({ type: POST_PRODUCT_TARFICATION_REQUEST });
    return axios
      .post(`${getConfig('apiUrl')}/produit/obtenirTarification`, data)
      .then(response => {
        dispatch(dispatch_success(response.data, POST_PRODUCT_TARFICATION_SUCCESS));
      })
      .catch(error => {
        dispatch({ type: POST_PRODUCT_TARFICATION_FAIL, payload: error });
      });
  };
};

const post_prospect = data => {
  return dispatch => {
    dispatch({ type: POST_PROSPECT_REQUEST });
    return axios
      .post(`${getConfig('apiUrl')}/prospect/creer`, data)
      .then(response => {
        dispatch(dispatch_success(response.data, POST_PROSPECT_SUCCESS));
      })
      .catch(error => {
        dispatch({ type: POST_PROSPECT_FAIL, payload: error });
      });
  };
};

const post_referentiel_activite = data => {
  return dispatch => {
    dispatch({ type: POST_REFERENTIEL_ACTIVITE_REQUEST });
    return axios
      .post(`${getConfig('apiUrl')}/referentiel/activite/lister`, data)
      .then(response => {
        dispatch(dispatch_success(response.data, POST_REFERENTIEL_ACTIVITE_SUCCESS));
      })
      .catch(error => {
        dispatch({ type: POST_REFERENTIEL_ACTIVITE_FAIL, payload: error });
      });
  };
};

const get_codes_postaux = code => {
  return dispatch => {
    dispatch({ type: GET_CODES_POSTAUX_REQUEST });
    return axios
      .get(`${getConfig('apiUrl')}/referentiel/commune/codesPostaux/rechercher/${code}`)
      .then(response => {
        dispatch(dispatch_success(response.data, GET_CODES_POSTAUX_SUCCESS));
      })
      .catch(error => {
        dispatch({ type: GET_CODES_POSTAUX_FAIL, payload: error });
      });
  };
};

const get_city_by_cp = code => {
  return dispatch => {
    dispatch({ type: GET_CITY_BY_CP_REQUEST });
    return axios
      .get(`${getConfig('apiUrl')}/referentiel/commune/communes/rechercher/${code}`)
      .then(response => {
        dispatch(dispatch_success(response.data, GET_CITY_BY_CP_SUCCESS));
      })
      .catch(error => {
        dispatch({ type: GET_CITY_BY_CP_FAIL, payload: error });
      });
  };
};

const get_value_stock = () => {
  return dispatch => {
    dispatch({ type: GET_VALEUR_STOCK_REQUEST });
    return axios
      .get(`${getConfig('apiUrl')}/referentiel/codifications/lister/NULL/VALEUR_STOCK`)
      .then(response => {
        dispatch(dispatch_success(response.data, GET_VALEUR_STOCK_SUCCESS));
      })
      .catch(error => {
        dispatch({ type: GET_VALEUR_STOCK_FAIL, payload: error });
      });
  };
};

const get_codifications_infobulles = () => {
  return dispatch => {
    dispatch({ type: GET_CODIFICATIONS_INFOBULLE_REQUEST });
    return axios
      .get(`${getConfig('apiUrl')}/referentiel/codifications/lister/NULL/INFOBULLE`)
      .then(response => {
        dispatch(dispatch_success(response.data, GET_CODIFICATIONS_INFOBULLE_SUCCESS));
      })
      .catch(error => {
        dispatch({ type: GET_CODIFICATIONS_INFOBULLE_FAIL, payload: error });
      });
  };
};

const get_codifications_infobulles_titre = () => {
  return dispatch => {
    dispatch({ type: GET_CODIFICATIONS_INFOBULLE_TITRE_REQUEST });
    return axios
      .get(`${getConfig('apiUrl')}/referentiel/codifications/lister/NULL/INFOBULLE_TITRE`)
      .then(response => {
        dispatch(dispatch_success(response.data, GET_CODIFICATIONS_INFOBULLE_TITRE_SUCCESS));
      })
      .catch(error => {
        dispatch({ type: GET_CODIFICATIONS_INFOBULLE_TITRE_FAIL, payload: error });
      });
  };
};

const get_codifications_menu = () => {
  return dispatch => {
    dispatch({ type: GET_CODIFICATIONS_MENU_REQUEST });
    return axios
      .get(`${getConfig('apiUrl')}/referentiel/codifications/lister/NULL/MENU_GAUCHE`)
      .then(response => {
        dispatch(dispatch_success(response.data, GET_CODIFICATIONS_MENU_SUCCESS));
      })
      .catch(error => {
        dispatch({ type: GET_CODIFICATIONS_MENU_FAIL, payload: error });
      });
  };
};

const data_form = (data, type) => {
  return dispatch => {
    dispatch(dispatch_request(data, GET_DATA_FORM_REQUEST, type));
    if (data !== null || typeof data !== 'undefined') {
      dispatch(dispatch_success(data, GET_DATA_FORM_SUCCESS, type));
    } else {
      dispatch(dispatch_fail(data, GET_DATA_FORM_FAIL, type));
    }
  };
};

const appActions = {
  post_referentiel_reseau,
  post_referentiel_activite,
  get_codes_postaux,
  get_city_by_cp,
  data_form,
  get_value_stock,
  post_product_tarification,
  post_prospect,
  get_codifications_infobulles,
  get_codifications_infobulles_titre,
  get_codifications_menu
};

export default appActions;
