import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";

import logo from "assets/img/bi-gout.png";
import umbrella from "assets/img/group-116.png";

import SquareTarification from "components/SquareTarification/SquareTarification";
import { StickyAlert } from "components/StickyAlert/StickyAlert";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import appActions from "redux/actions/appActions";

const useStyles = makeStyles({
  container: {
    height: "100vh",
    width: "100%"
    // overflowX: "hidden",
    // position: "absolute",
    // bottom: 0
  },
  logo: {
    marginBottom: 16,
    marginTop: 16,
    marginLeft: 40
  },
  paragraphe: {
    fontFamily: "Work Sans",
    fontSize: 32,
    fontWeight: 900,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#4a4a49"
  },
  span: {
    color: "#e85211"
  },
  img: {
    display: "block",
    margin: " 0 auto"
  },
  square: {
    marginTop: 24
  },
  footer: {
    minHeight: 122,
    boxShadow: "0 12px 34px 0 #e9e0dc, 0 4px 4px 0 rgba(236, 228, 224, 0.72)",
    backgroundColor: "#ffffff"
  },
  priceTotal: {
    fontFamily: "Work Sans",
    fontSize: 36,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.86,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#e85211",
    marginBlockStart: "0.5em",
    marginBlockEnd: "0.25em"
  },
  priceLibelle: {
    color: "#4a4a49",
    height: "16px",
    opacity: "0.8",
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Work Sans",
    fontWeight: "normal",
    lineHeight: "normal",
    fontStretch: "normal",
    marginBottom: "25px",
    letterSpacing: "normal",
    textAlign: "center",
    marginBlockStart: "0.25em"
  },
  ttc: {
    opacity: 0.8,
    fontFamily: "Work Sans",
    fontSize: 20,
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#4a4a49"
  },
  buttonPrevDesktop: {
    marginTop: 40,
    height: 42,
    borderRadius: 8,
    backgroundColor: "#ffff",
    border: "solid 2px #E85313",
    color: "#E85313",
    fontFamily: "Work Sans",
    fontSize: 14,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    marginRight: 30
  },
  buttonNextDesktop: {
    fontFamily: "Work Sans",
    fontSize: 14,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    marginTop: 40,
    height: 42,
    borderRadius: 8,
    backgroundColor: "#E85313",
    color: "#ffff"
  },
  buttonPrevMobile: {
    marginBottom: 16,
    width: "90%",
    height: 42,
    borderRadius: 8,
    backgroundColor: "#ffff",
    border: "solid 2px #E85313",
    color: "#E85313"
  },
  buttonNextMobile: {
    width: "90%",
    height: 42,
    borderRadius: 8,
    backgroundColor: "#E85313",
    color: "#ffff",
    marginBottom: 16
  },
  error: {
    color: "red",
    fontFamily: "Work Sans"
  },
  paragrapheProductEmpty: {
    fontFamily: "Work Sans",
    fontSize: 28,
    fontWeight: 400,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#4a4a49"
  }
});
const array = [];
export default function Tarification(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const tarification = useSelector(store => store.appReducer.tarification);
  const formReducer = useSelector(store => store.formReducer);

  const [totalTarif, setTotalTarif] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (totalTarif !== null) {
      dispatch(appActions.data_form(totalTarif, "totalTarif"));
    }
  }, [totalTarif, dispatch]);

  let calcul = 0;
  const retrievePrice = (data, type) => {
    const code = data && data.code;
    const find = array.findIndex(data => data.code === code);
    if (find === -1) {
      array.push(data);
    }
    if (type === "delete") {
      array.splice(find, 1);
    }
    dispatch(appActions.data_form(array, "tarif"));
    array.forEach(tarif => {
      calcul += tarif.montantMensuel;
    });
    setTotalTarif(calcul.toFixed(2));
    setError(false);
  };

  const onSubmit = () => {
    if (array.length > 0) {
      setRedirect(true);
    } else {
      setError(true);
    }
  };

  if (!formReducer.jwt || (formReducer.jwt && formReducer.jwt.data === null)) {
    return <Redirect to="/step-1" />;
  }

  if (redirect) {
    return <Redirect to="/finalisation" />;
  }

  return (
    <Grid container justify="center" className={classes.container} spacing={1}>
      <StickyAlert />
      <Grid item xs={12} sm={9} className={classes.logo}>
        <img src={logo} alt="logo" />
      </Grid>
      <Grid item xs={12} sm={12}>
        <p className={classes.paragraphe}>
          Choisissez les garanties{" "}
          <span className={classes.span}>qui vous conviennent !</span>
        </p>
      </Grid>

      <Grid item xs={12} sm={12}>
        <img className={classes.img} src={umbrella} alt="umbrella" />
      </Grid>

      {tarification &&
      tarification.data &&
      Array.isArray(tarification.data.body) &&
      tarification.data.body.length > 0
        ? tarification.data.body.map((tarif, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <SquareTarification
                tarif={tarif}
                retrievePrice={retrievePrice}
                index={index}
              />
            </Grid>
          ))
        : tarification &&
          tarification.error === null && (
            <CircularProgress
              style={{ color: "#E85313", marginBottom: 40 }}
              size={50}
            />
          )}
      {tarification && tarification.error && (
        <p className={classes.paragrapheProductEmpty}>
          Aucun produit disponible, veuillez réessayer ultérieurement.
        </p>
      )}

      <Hidden only={"xs"}>
        <Grid
          container
          justify="flex-start"
          className={classes.footer}
          style={{ position: "sticky", bottom: 0 }}
        >
          <Grid
            container
            justify="center"
            alignItems="flex-start"
            item
            xs={12}
            sm={4}
          >
            <Button
              component={Link}
              to="/step-3"
              variant="contained"
              className={classes.buttonPrevDesktop}
            >
              Précédent
            </Button>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="flex-start"
            item
            xs={12}
            sm={4}
          >
            {!error && (
              <p className={classes.priceTotal}>
                {formReducer && formReducer.totalTarif
                  ? formReducer.totalTarif.data
                  : 0}
                € <span className={classes.ttc}>TTC/mois</span>
              </p>
            )}
            {error && (
              <p className={classes.error}>
                Veuillez choisir un ou plusieurs produits.
              </p>
            )}
            <p className={classes.priceLibelle}>
              Bonne nouvelle ! Grâce au mécénat de CFDP Assurances, vous
              bénéficiez d&apos;une protection juridique gratuite sur tous vos
              contrats.
            </p>
          </Grid>
          {tarification &&
            tarification.data &&
            Array.isArray(tarification.data.body) &&
            tarification.data.body.length > 0 && (
              <Grid
                container
                item
                xs={12}
                sm={4}
                alignItems="flex-start"
                justify="center"
              >
                <Button
                  onClick={onSubmit}
                  variant="contained"
                  className={classes.buttonNextDesktop}
                >
                  Valider mon choix
                </Button>
              </Grid>
            )}
        </Grid>
      </Hidden>

      <Hidden only={["sm", "md", "lg", "xl"]}>
        <Grid container justify="flex-start" className={classes.footer}>
          <Grid
            container
            justify="center"
            alignItems="flex-end"
            item
            xs={12}
            sm={4}
          >
            {!error && (
              <p className={classes.priceTotal}>
                {formReducer && formReducer.totalTarif
                  ? formReducer.totalTarif.data
                  : 0}
                € <span className={classes.ttc}>TTC/mois</span>
              </p>
            )}
            {error && (
              <p className={classes.error}>Veuillez choisir un produit.</p>
            )}
            <p>
              Bonne nouvelle ! Grâce au mécénat de CFDP Assurances, vous
              bénéficiez d&apos;une protection juridique gratuite sur tous vos
              contrats.
            </p>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="flex-end"
            item
            xs={12}
            sm={4}
          >
            <Button
              component={Link}
              to="/step-3"
              variant="contained"
              className={classes.buttonPrevMobile}
            >
              Précédent
            </Button>
          </Grid>
          {tarification &&
            tarification.data &&
            Array.isArray(tarification.data.body) &&
            tarification.data.body.length > 0 && (
              <Grid
                container
                item
                xs={12}
                sm={4}
                alignItems="flex-end"
                justify="center"
              >
                <Button
                  onClick={onSubmit}
                  variant="contained"
                  className={classes.buttonNextMobile}
                >
                  Valider mon choix
                </Button>
              </Grid>
            )}
        </Grid>
      </Hidden>
    </Grid>
  );
}
