import {
  POST_REFERENTIEL_RESEAU_FAIL,
  POST_REFERENTIEL_RESEAU_REQUEST,
  POST_REFERENTIEL_RESEAU_SUCCESS,
  GET_CODES_POSTAUX_FAIL,
  GET_CODES_POSTAUX_REQUEST,
  GET_CODES_POSTAUX_SUCCESS,
  POST_REFERENTIEL_ACTIVITE_FAIL,
  POST_REFERENTIEL_ACTIVITE_REQUEST,
  POST_REFERENTIEL_ACTIVITE_SUCCESS,
  GET_CITY_BY_CP_FAIL,
  GET_CITY_BY_CP_REQUEST,
  GET_CITY_BY_CP_SUCCESS,
  GET_VALEUR_STOCK_FAIL,
  GET_VALEUR_STOCK_REQUEST,
  GET_VALEUR_STOCK_SUCCESS,
  POST_PRODUCT_TARFICATION_FAIL,
  POST_PRODUCT_TARFICATION_REQUEST,
  POST_PRODUCT_TARFICATION_SUCCESS,
  POST_PROSPECT_FAIL,
  POST_PROSPECT_REQUEST,
  POST_PROSPECT_SUCCESS,
  GET_CODIFICATIONS_INFOBULLE_FAIL,
  GET_CODIFICATIONS_INFOBULLE_REQUEST,
  GET_CODIFICATIONS_INFOBULLE_SUCCESS,
  GET_CODIFICATIONS_INFOBULLE_TITRE_FAIL,
  GET_CODIFICATIONS_INFOBULLE_TITRE_REQUEST,
  GET_CODIFICATIONS_INFOBULLE_TITRE_SUCCESS,
  GET_CODIFICATIONS_MENU_FAIL,
  GET_CODIFICATIONS_MENU_REQUEST,
  GET_CODIFICATIONS_MENU_SUCCESS
} from '../actionTypes';

const initialState = {
  referentielReseau: {
    isLoading: null,
    error: null,
    data: null
  },
  codesPostaux: {
    isLoading: null,
    error: null,
    data: null
  },
  listActivite: {
    isLoading: null,
    error: null,
    data: null
  },
  city: {
    isLoading: null,
    error: null,
    data: null
  },
  valueStock: {
    isLoading: null,
    error: null,
    data: null
  },
  tarification: {
    isLoading: null,
    error: null,
    data: null
  },
  prospect: {
    isLoading: null,
    error: null,
    data: null
  },
  codifications: {
    isLoading: null,
    error: null
  },
  codificationsTitre: {
    isLoading: null,
    error: null,
    data: null
  },
  codificationsMenu: {
    isLoading: null,
    error: null,
    data: null
  }
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case POST_REFERENTIEL_RESEAU_REQUEST:
      return {
        ...state,
        referentielReseau: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    case POST_REFERENTIEL_RESEAU_SUCCESS:
      return {
        ...state,
        referentielReseau: {
          isLoading: false,
          error: false,
          data: action.data
        }
      };
    case POST_REFERENTIEL_RESEAU_FAIL:
      return {
        ...state,
        referentielReseau: {
          isLoading: false,
          error: action.payload,
          data: false
        }
      };
    case POST_REFERENTIEL_ACTIVITE_REQUEST:
      return {
        ...state,
        listActivite: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    case POST_REFERENTIEL_ACTIVITE_SUCCESS:
      return {
        ...state,
        listActivite: {
          isLoading: false,
          error: false,
          data: action.data
        }
      };
    case POST_REFERENTIEL_ACTIVITE_FAIL:
      return {
        ...state,
        listActivite: {
          isLoading: false,
          error: action.payload,
          data: false
        }
      };
    case POST_PRODUCT_TARFICATION_REQUEST:
      return {
        ...state,
        tarification: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    case POST_PRODUCT_TARFICATION_SUCCESS:
      return {
        ...state,
        tarification: {
          isLoading: false,
          error: false,
          data: action.data
        }
      };
    case POST_PRODUCT_TARFICATION_FAIL:
      return {
        ...state,
        tarification: {
          isLoading: false,
          error: action.payload,
          data: false
        }
      };
    case POST_PROSPECT_REQUEST:
      return {
        ...state,
        prospect: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    case POST_PROSPECT_SUCCESS:
      return {
        ...state,
        prospect: {
          isLoading: false,
          error: false,
          data: action.data
        }
      };
    case POST_PROSPECT_FAIL:
      return {
        ...state,
        prospect: {
          isLoading: false,
          error: action.payload,
          data: false
        }
      };
    case GET_CODES_POSTAUX_REQUEST:
      return {
        ...state,
        codesPostaux: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    case GET_CODES_POSTAUX_SUCCESS:
      return {
        ...state,
        codesPostaux: {
          isLoading: false,
          error: false,
          data: action.data
        }
      };
    case GET_CODES_POSTAUX_FAIL:
      return {
        ...state,
        codesPostaux: {
          isLoading: false,
          error: action.payload,
          data: false
        }
      };
    case GET_CITY_BY_CP_REQUEST:
      return {
        ...state,
        city: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    case GET_CITY_BY_CP_SUCCESS:
      return {
        ...state,
        city: {
          isLoading: false,
          error: false,
          data: action.data
        }
      };
    case GET_CITY_BY_CP_FAIL:
      return {
        ...state,
        city: {
          isLoading: false,
          error: action.payload,
          data: false
        }
      };
    case GET_VALEUR_STOCK_REQUEST:
      return {
        ...state,
        valueStock: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    case GET_VALEUR_STOCK_SUCCESS:
      return {
        ...state,
        valueStock: {
          isLoading: false,
          error: false,
          data: action.data
        }
      };
    case GET_VALEUR_STOCK_FAIL:
      return {
        ...state,
        valueStock: {
          isLoading: false,
          error: action.payload,
          data: false
        }
      };

    case GET_CODIFICATIONS_INFOBULLE_REQUEST:
      return {
        ...state,
        codifications: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    case GET_CODIFICATIONS_INFOBULLE_SUCCESS:
      return {
        ...state,
        codifications: {
          isLoading: false,
          error: false,
          data: action.data
        }
      };
    case GET_CODIFICATIONS_INFOBULLE_FAIL:
      return {
        ...state,
        codifications: {
          isLoading: false,
          error: action.payload,
          data: false
        }
      };
    case GET_CODIFICATIONS_INFOBULLE_TITRE_REQUEST:
      return {
        ...state,
        codificationsTitre: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    case GET_CODIFICATIONS_INFOBULLE_TITRE_SUCCESS:
      return {
        ...state,
        codificationsTitre: {
          isLoading: false,
          error: false,
          data: action.data
        }
      };
    case GET_CODIFICATIONS_INFOBULLE_TITRE_FAIL:
      return {
        ...state,
        codificationsTitre: {
          isLoading: false,
          error: action.payload,
          data: false
        }
      };
    case GET_CODIFICATIONS_MENU_REQUEST:
      return {
        ...state,
        codificationsMenu: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    case GET_CODIFICATIONS_MENU_SUCCESS:
      return {
        ...state,
        codificationsMenu: {
          isLoading: false,
          error: false,
          data: action.data
        }
      };
    case GET_CODIFICATIONS_MENU_FAIL:
      return {
        ...state,
        codificationsMenu: {
          isLoading: false,
          error: action.payload,
          data: false
        }
      };
    default:
      return state;
  }
}
