import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import appActions from 'redux/actions/appActions';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export default function SquareIncrement(props) {
  const useStyles = makeStyles({
    paragraphe: {
      fontFamily: 'Work Sans',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#323232',
      marginLeft: props.justify === 'flex-start' && 16
    },
    increment: {
      fontFamily: 'Work Sans',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      backgroundColor: '#ffffff',
      marginLeft: props.justify === 'flex-start' && 16
    },
    square: {
      width: 44,
      height: 44,
      borderRadius: 4,
      border: 'solid 1px #d6d6d6',
      backgroundColor: '#ffffff'
    },
    number: {
      textAlign: 'center',
      margin: 15,
      fontFamily: 'Work Sans',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#323232'
    }
  });

  const classes = useStyles();

  const dispatch = useDispatch();

  const [number, setNumber] = useState(props.defaultValue ? props.defaultValue : props.type === 'adult' ? 1 : 0);

  const increment = () => {
    if (props.type === 'adult') {
      if (number <= 1) {
        setNumber(number + 1);
      }
    } else {
      setNumber(number + 1);
    }
  };

  const decrement = () => {
    if (props.type === 'adult') {
      if (number > 1) {
        setNumber(number - 1);
      }
    } else {
      if (number > 0) {
        setNumber(number - 1);
      }
    }
  };

  const HandleTitle = () => {
    if (props.type === 'adult') {
      return "Nombre d'adulte(s)";
    } else {
      return 'Nombre d’enfant(s)';
    }
  };

  useEffect(() => {
    dispatch(appActions.data_form(number, props.type));
  }, [number, props.type, dispatch]);

  return (
    <div>
      <p className={classes.paragraphe}>{<HandleTitle />}</p>
      <div className={classes.increment}>
        <RemoveIcon onClick={() => decrement()} style={{ marginRight: 16, cursor: 'pointer' }} />
        <div className={classes.square}>
          <div className={classes.number}>{number}</div>
        </div>
        <AddIcon onClick={() => increment()} style={{ marginLeft: 16, cursor: 'pointer' }} />
      </div>
    </div>
  );
}

SquareIncrement.propTypes = {
  type: PropTypes.string,
  justify: PropTypes.string,
  defaultValue: PropTypes.number
};
